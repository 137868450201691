import React from 'react';
import { Outlet } from 'react-router-dom';
import { AppConfigPresets } from './View';

/**
 * The root of the application.
 * Here you can define the layout of the application like navbar, footer, etc.
 *
 * Any changes to the layout will be reflected in all routes of the application.
 */
export default function Root() {
    const corps = AppConfigPresets.map((config) => config.journey);

    return (
        <div>
            <div>
                {corps.map((corp) => (
                    <button
                        key={corp.apiToken}
                        onClick={() => {
                            window.location.href = '/' + corp.corpCode;
                        }}
                        style={{ marginRight: 4 }}
                    >
                        {corp.corpCode} - {corp.dev ? 'API' : 'PROD'}
                    </button>
                ))}
            </div>

            <Outlet />
        </div>
    );
}
