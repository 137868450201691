import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import './App.scss';

import Root from './routes/Root';
import View from './routes/View';

/**
 * The loader function can be used to preload any data or assets required for the route.
 *
 * Typically we use this to load location data from rapidstor before showing the page.
 * Ideally we should show a loading spinner while the loader is running.
 */
const loader = () => {
    return 'Website Name';
};

const JourneyLoader = () => {
    return '';
};

export default createBrowserRouter(
    [
        {
            path: '/',
            element: <Root />,
            loader: loader,
            children: [
                {
                    path: '/:corpCode',
                    element: <View />,
                    loader: JourneyLoader,
                },
            ],
        },
    ],
    {
        basename: '/',
    }
);
