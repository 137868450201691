import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

export interface PageConfig {
    storageType: boolean;
    unitListing: boolean;
    bookUnit: 'standard' | 'map';
}

export type SupportedLanguages = 'en' | 'zh_HK';

export interface JourneyConfig {
    locationCode: string;
    corpCode: string;
    apiToken: string;
    corpToken: string;
    themeColor: string;
    dev?: boolean;
    startPosition?: number;
    unitTypeNames?: string;
    unitTypeRegex?: string;
    language?: string;
    configSelector?: boolean;
    loqate?: LoqateConfigObject;
}

export interface LoqateConfigObject {
    apiKey: string;
    accountCode: string;
    host: string;
}

export interface AppConfig {
    pages: PageConfig;
    journey: JourneyConfig;
}

export const AppConfigPresets: AppConfig[] = [
    {
        pages: {
            storageType: true,
            unitListing: true,
            bookUnit: 'standard',
        },
        journey: {
            locationCode: 'L004',
            corpCode: 'CHAH',
            apiToken: 'imnXCqv9NheohrwvTUg3YygIh0NEr1um',
            corpToken:
                '2ba2f3169f9cc2df18ceecc85884dade103ddfd3ebefee89a6c7b2ccf85976e9200c5fc41919e4da94491b79f7217c0d',
            themeColor: '#000',
            dev: true,
        },
    },
    {
        pages: {
            storageType: true,
            unitListing: true,
            bookUnit: 'standard',
        },
        journey: {
            locationCode: 'TEST',
            corpCode: 'CRUK',
            apiToken: '0zbo0yM8TFiiM0dmowS8f46h2M4WmClK',
            corpToken:
                'adfe4749288ea6045aafabadc8aab35a803ef666844261ac4149a6f7fde9e2e45f116e4aff94d16aedf2c37aed410331',
            themeColor: '#000',
            loqate: {
                apiKey: 'RE21-MX62-RJ68-JM76',
                accountCode: 'UKSTO11111',
                host: 'UKSTO11111.pcapredict.com',
            },
        },
    },
    {
        pages: {
            storageType: true,
            unitListing: true,
            bookUnit: 'standard',
        },
        journey: {
            locationCode: 'CCYZ',
            corpCode: 'CCYZ',
            apiToken: 'V6Aiu6szoqnESruITHXMxCel8S6zi6UG',
            corpToken:
                'b169dcc67c36b788aeb9ba65e44d601b628028accd57f786ee7d8b28d7c080c0d28cd75beabf09e9c3adc38a8982568b',
            themeColor: '#000',
            dev: true,
        },
    },
    {
        pages: {
            storageType: true,
            unitListing: true,
            bookUnit: 'standard',
        },
        journey: {
            locationCode: 'L002',
            corpCode: 'CZJS',
            apiToken: 'YiJ4HDqh1RaJSCcS0HE8QxGyTmDJvUQD',
            corpToken:
                '88a21a945cd10722e4d037f1c34b52975acb48d9cacdd78722e983b96133731ac27b6381eb91e9079ac0e06d3cd3fcee',
            themeColor: '#000',
        },
    },
    {
        pages: {
            storageType: false,
            unitListing: true,
            bookUnit: 'standard',
        },
        journey: {
            locationCode: 'SLSET',
            corpCode: 'CSEQ',
            apiToken: 'oiYhdm8XxjVmbCNbMEP2IT0VoIDLVTZU',
            corpToken:
                '307cee32f8700449dac8435247ec2e7ff4b296a5625da0f55f876d3a515aa927c7a8fe3a00b80c4bad734607412ed4d4',
            themeColor: '#000',
        },
    },
    {
        pages: {
            storageType: false,
            unitListing: true,
            bookUnit: 'map',
        },
        journey: {
            locationCode: 'L010',
            corpCode: 'CNCK',
            apiToken: 'sGbqdml30gb6WxbxVd8guErh3AUZInmo',
            corpToken:
                '206308733f3443463ab33abec59d32a24497559b620c0fb515f6ca6c39cdbf93a0d1195803bfec4d7e4afc6d6c62947f',
            themeColor: '#000',
            dev: true,
        },
    },
    {
        pages: {
            storageType: true,
            unitListing: true,
            bookUnit: 'standard',
        },
        journey: {
            locationCode: 'SFT1',
            corpCode: 'CHWZ',
            apiToken: 'S3Ugm3f4M3LpVEThdmTNZdV7LFeNQQKx',
            corpToken:
                'bec0fc41a32d623722e2d5ca449b3499891ca2e289c83d254f4ed34bee0fc60f0299f15240527f5640f437cd422a475f',
            language: 'zh-HK',
            themeColor: '#14338d',
        },
    },
];

export default function View() {
    const { corpCode } = useParams();

    useEffect(() => {
        const configToUse = AppConfigPresets.find(
            (config) => config.journey.corpCode === corpCode
        );

        if (!configToUse) {
            console.error(`No config found for corpCode: ${corpCode}`);
            return;
        }

        const initApp = async () => {
            const contents = await fetch(
                `https://develop.rapidstor-v2-frontend.pages.dev/`
            );
            const result = await contents.text();
            const parser = new DOMParser();
            const doc = parser.parseFromString(result, `text/html`);
            const script = doc.querySelector(`head script`);
            const st = document.createElement(`script`);
            const p = document.getElementById(`rapidstor-v2-frontend`);
            st.src =
                `https://develop.rapidstor-v2-frontend.pages.dev` +
                script?.getAttribute(`src`);
            st.setAttribute(`type`, `module`);
            st.setAttribute(`crossorigin`, 'true');
            p?.appendChild(st);
            const container = document.getElementById(`rapidstor-v2-frontend`);
            container?.setAttribute('data-appConfig', JSON.stringify(configToUse));
        };

        initApp();
    }, [corpCode]);

    return (
        <div>
            <div
                id='rapidstor-v2-frontend'
                className='rapidstor-v2-frontend'
                style={{
                    minHeight: '100vh',
                }}
            ></div>
        </div>
    );
}
