import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';

import { applicationSlice } from './applicationSlice';

const reducer = combineReducers({
    [applicationSlice.name]: applicationSlice.reducer,
});

const store = configureStore({
    reducer,
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
