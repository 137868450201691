import { createSlice } from '@reduxjs/toolkit';

import { RootState } from './index';

/**
 * This is where the state interface and initial state are defined.
 */
interface State {
    name: string | null;
}
const initialState: State = {
    name: null,
};

/**
 * Create the slice and the reducers.
 */
export const applicationSlice = createSlice({
    name: 'application',
    initialState,
    reducers: {
        /**
         * Example of a reducer function.
         */
        setName: (state, action: { payload: string; type: string }) => {
            state.name = action.payload;
        },
    },
});

export const { setName } = applicationSlice.actions;

/**
 * Example of a selector function.
 */
export const selectName = (state: RootState) => state.application.name;
