import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import store from './store';
import Router from './router';
import './App.scss';

ReactDOM.createRoot(document.getElementById('react-template') as HTMLElement).render(
    <React.StrictMode>
        <Provider store={store}>
            <RouterProvider router={Router} />
        </Provider>
    </React.StrictMode>
);
